<template>
  <v-row>
    <v-col md="4">
      <div>
        <h5 class="font-weight-bold mb-0 text-left red--text text-center mb-4">
          <!--Consejo Mexicano de Patología Clínica y Medicina de Laboratorio, A. C.-->
          CONSEJO MEXICANO DE PATOLOGÍA CLÍNICA Y MEDICINA DE LABORATORIO, A. C.
        </h5>
      </div>

    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'SectionSitios',

    data: () => ({
      multiply: 6,

      cards: [
        {
          title: 'CERTIFICACION',
          icon: 'mdi-mouse',
          outlined: true,
          src: require('@/assets/compac/cert.svg'),
          ruta:'/certificaciones'
        },
        {
          title: 'RECERTIFICACION',
          color: 'primary',
          dark: true,
          icon: 'mdi-headphones',
          src: require('@/assets/compac/recert.svg'),
          ruta:'/recertificaciones'
        },
        {
          title: 'EDUCACION CONTINUA',
          icon: 'mdi-vuetify',
          outlined: true,
          src: require('@/assets/compac/edu.svg'),
          ruta:'/educacion-continua'
        },
      ],
      marketing: [
        'Establecer los estándares para la evaluación de la certificacion',
        'Establecer los parámetros para la calificación de la educación continua',
        'Establecer los procedimientos para la revalidación de la certificacion',
        'Establecer una comunicación continua con las unidades médicas formadoras de especialistas',
        'Mantener un registro actualizado de los especialistas certificados y revalidados en Patología Clínica',
        'Colaborar con la autoridad en la información de Patología Clínica a través del CONACEM',
        'Colaborar con las Universidades y unidades médicas formadoras de especialistas en la  elaboración y/o modificación de los programas académicos de la especialidad',

      ],
    }),
    computed: {
      classes () {
        return [
          this.outlined && 'base-avatar--outlined',
        ]
      },
      isDark () {
        return this.theme.isDark || this.dark
      },
      outlineSize () {
        return Number(this.size) + (this.size / this.multiply)
      },
      styles () {
        const margin = this.size / (this.multiply * 2)

        return {
          // Aligns the outline content with the content
          margin: `-${margin}px 0 0 -${margin}px`,
        }
      },
    },
  }
</script>


<style lang="sass">
  .base-avatar
    border-radius: 50%
    position: relative
    overflow: visible

    &__outline
      position: absolute !important
      left: 0
      top: 0

    &.base-avatar--outlined .base-avatar__avatar
      border-width: thin
      border-style: solid

    .theme--light .base-avatar--outlined .base-avatar__avatar
      border-color: rgba(0, 0, 0, .12) !important

    .theme--dark .base-avatar--outlined .base-avatar__avatar
      border-color: rgba(255, 255, 255, .12) !important
</style>

